import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

export async function getCallDurationForMonth() {
  // Define the start of the current month
  const startOfMonth = new Date();
  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);

  // Fetch all records for the current month
  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId, created_at")
    .gte("created_at", startOfMonth.toISOString())
    .lte("created_at", new Date().toISOString());

  if (error) {
    console.error("Error fetching call duration data:", error);
    return 0;
  }

  if (!data || data.length === 0) {
    return 0;
  }

  // Group data by sessionId
  const sessions = {};
  data.forEach((row) => {
    const { sessionId, created_at } = row;
    if (!sessionId) return; // Skip if sessionId is null

    if (!sessions[sessionId]) {
      sessions[sessionId] = {
        start_time: new Date(created_at),
        end_time: new Date(created_at),
      };
    } else {
      const currentCreatedAt = new Date(created_at);
      if (currentCreatedAt < sessions[sessionId].start_time) {
        sessions[sessionId].start_time = currentCreatedAt;
      }
      if (currentCreatedAt > sessions[sessionId].end_time) {
        sessions[sessionId].end_time = currentCreatedAt;
      }
    }
  });

  // Calculate total duration in minutes
  const totalDurationInMinutes = Object.values(sessions).reduce(
    (sum, { start_time, end_time }) => {
      const sessionDurationInMinutes = (end_time - start_time) / (1000 * 60); // Convert milliseconds to minutes
      return sum + sessionDurationInMinutes;
    },
    0
  );

  return Math.round(totalDurationInMinutes);
}

export async function getCallDurationForLastMonth() {
  // Define the start and end of the last month
  const today = new Date();
  const startOfLastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of last month

  // Fetch all records for the last month
  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId, created_at")
    .gte("created_at", startOfLastMonth.toISOString())
    .lte("created_at", endOfLastMonth.toISOString());

  if (error) {
    console.error("Error fetching last month call duration data:", error);
    return 0;
  }

  if (!data || data.length === 0) {
    return 0;
  }

  // Group data by sessionId
  const sessions = {};
  data.forEach((row) => {
    const { sessionId, created_at } = row;
    if (!sessionId) return; // Skip if sessionId is null

    if (!sessions[sessionId]) {
      sessions[sessionId] = {
        start_time: new Date(created_at),
        end_time: new Date(created_at),
      };
    } else {
      const currentCreatedAt = new Date(created_at);
      if (currentCreatedAt < sessions[sessionId].start_time) {
        sessions[sessionId].start_time = currentCreatedAt;
      }
      if (currentCreatedAt > sessions[sessionId].end_time) {
        sessions[sessionId].end_time = currentCreatedAt;
      }
    }
  });

  // Calculate total duration in minutes
  const totalDurationInMinutes = Object.values(sessions).reduce(
    (sum, { start_time, end_time }) => {
      const sessionDurationInMinutes = (end_time - start_time) / (1000 * 60); // Convert milliseconds to minutes
      return sum + sessionDurationInMinutes;
    },
    0
  );

  return Math.round(totalDurationInMinutes * 10) / 10; // Round to the nearest tenth
}

export async function getCallVolumeForMonth() {
  // Define the start of the current month
  const startOfMonth = new Date();
  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);

  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId")
    .gte("created_at", startOfMonth.toISOString())
    .lte("created_at", new Date().toISOString());

  if (error) {
    console.error("Error fetching call volume data:", error);
    return 0;
  }

  if (!data || data.length === 0) {
    return 0;
  }

  // Use a Set to get unique session IDs, excluding nulls
  const uniqueSessionIds = new Set(
    data.map((row) => row.sessionId).filter((id) => id !== null)
  );

  return uniqueSessionIds.size;
}

export async function getCallDurationForSession(sessionID) {
  // Fetch all records for the specified sessionID
  const { data, error } = await supabase
    .from("conversationLog")
    .select("created_at")
    .eq("sessionId", sessionID);

  if (error) {
    console.error("Error fetching call duration data for session:", error);
    return 0;
  }

  if (!data || data.length === 0) {
    console.warn("No data found for the provided session ID.");
    return 0;
  }

  // Calculate the duration for the session
  const start_time = new Date(
    Math.min(...data.map((row) => new Date(row.created_at)))
  );
  const end_time = new Date(
    Math.max(...data.map((row) => new Date(row.created_at)))
  );

  // Calculate the session duration in minutes
  const sessionDurationInMinutes = (end_time - start_time) / (1000 * 60); // Convert milliseconds to minutes

  return Math.round(sessionDurationInMinutes);
}

export async function fetchDailyCallVolumeForWeek() {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 6); // Past 7 days including today

  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId, created_at")
    .gte("created_at", sevenDaysAgo.toISOString())
    .lte("created_at", today.toISOString());

  if (error) {
    console.error("Error fetching daily call volume data:", error);
    return [];
  }

  // Group data by day
  const dailyCounts = {};
  data.forEach((record) => {
    const date = new Date(record.created_at);
    const day = date.toLocaleDateString("en-US", { weekday: "short" }); // 'Mon', 'Tue', etc.
    dailyCounts[day] = (dailyCounts[day] || new Set()).add(record.sessionId);
  });

  // Prepare data for chart
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const result = daysOfWeek.map((day) => ({
    day,
    calls: dailyCounts[day] ? dailyCounts[day].size : 0,
  }));

  return result;
}

export async function fetchWeeklyCallVolumeForMonth() {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId, created_at")
    .gte("created_at", startOfMonth.toISOString())
    .lte("created_at", today.toISOString());

  if (error) {
    console.error("Error fetching weekly call volume data:", error);
    return [];
  }

  // Group data by week number
  const weekCounts = {};
  data.forEach((record) => {
    const date = new Date(record.created_at);
    const weekNumber = getWeekNumber(date); // Helper function
    weekCounts[weekNumber] = (weekCounts[weekNumber] || new Set()).add(
      record.sessionId
    );
  });

  // Prepare data for chart
  const result = Object.keys(weekCounts).map((week) => ({
    week: `Week ${week}`,
    calls: weekCounts[week].size,
  }));

  return result;
}

export async function fetchMonthlyCallVolumeForYear() {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);

  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId, created_at")
    .gte("created_at", startOfYear.toISOString())
    .lte("created_at", today.toISOString());

  if (error) {
    console.error("Error fetching monthly call volume data:", error);
    return [];
  }

  // Group data by month
  const monthCounts = {};
  data.forEach((record) => {
    const date = new Date(record.created_at);
    const month = date.toLocaleString("en-US", { month: "short" }); // 'Jan', 'Feb', etc.
    monthCounts[month] = (monthCounts[month] || new Set()).add(
      record.sessionId
    );
  });

  // Prepare data for chart
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const result = monthsOfYear.map((month) => ({
    month,
    calls: monthCounts[month] ? monthCounts[month].size : 0,
  }));

  return result;
}

export async function getCallVolumeForLastMonth() {
  const today = new Date();
  const startOfLastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId")
    .gte("created_at", startOfLastMonth.toISOString())
    .lte("created_at", endOfLastMonth.toISOString());

  if (error) {
    console.error("Error fetching last month's call volume data:", error);
    return 0;
  }

  if (!data || data.length === 0) {
    return 0;
  }

  // Use a Set to get unique session IDs, excluding nulls
  const uniqueSessionIds = new Set(
    data.map((row) => row.sessionId).filter((id) => id !== null)
  );

  return uniqueSessionIds.size;
}

export async function getResolutionRateForMonth() {
  const startOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  const endOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );

  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId, is_resolved, created_at")
    .gte("created_at", startOfMonth.toISOString()) // Filter from the start of the month
    .lte("created_at", endOfMonth.toISOString()); // To the end of the month

  if (error) {
    console.error("Error fetching resolution rate:", error);
    return 0;
  }

  // Create a map to store the resolution status for each unique sessionId
  const sessionResolutionMap = new Map();

  data.forEach((record) => {
    const { sessionId, is_resolved } = record;

    // Update the map only if the session is resolved (true)
    if (!sessionResolutionMap.has(sessionId) || is_resolved) {
      sessionResolutionMap.set(sessionId, is_resolved);
    }
  });

  // Calculate total sessions and resolved sessions
  const totalSessions = sessionResolutionMap.size;
  const resolvedSessions = Array.from(sessionResolutionMap.values()).filter(
    (resolved) => resolved === true
  ).length;

  // Calculate the resolution rate as a percentage
  return totalSessions > 0 ? (resolvedSessions / totalSessions) * 100 : 0;
}

// Helper function to get week number
function getWeekNumber(date) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - startOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
}

// Helper function to get day of week (Mon, Tue, ...)
function getDayOfWeek(date) {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return daysOfWeek[date.getDay()];
}

// Fetch resolution rate data for the current week
export async function getResolutionRateForWeek() {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sun) to 6 (Sat)
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek + 1); // Start from Monday

  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId, is_resolved, created_at")
    .gte("created_at", startOfWeek.toISOString())
    .lte("created_at", today.toISOString());

  if (error) {
    console.error("Error fetching weekly resolution rate data:", error);
    return [];
  }

  // Group data by day
  const dayResolutionMap = {};
  data.forEach((record) => {
    const date = new Date(record.created_at);
    const day = getDayOfWeek(date);
    if (!dayResolutionMap[day]) {
      dayResolutionMap[day] = { resolved: 0, total: 0 };
    }
    dayResolutionMap[day].total += 1;
    if (record.is_resolved) {
      dayResolutionMap[day].resolved += 1;
    }
  });

  // Prepare data for chart
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const result = daysOfWeek.map((day) => ({
    day,
    rate: dayResolutionMap[day]
      ? (
          (dayResolutionMap[day].resolved / dayResolutionMap[day].total) *
          100
        ).toFixed(1)
      : 0,
  }));

  return result;
}

// Fetch resolution rate data for the current year
export async function getResolutionRateForYear() {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);

  const { data, error } = await supabase
    .from("conversationLog")
    .select("sessionId, is_resolved, created_at")
    .gte("created_at", startOfYear.toISOString())
    .lte("created_at", today.toISOString());

  if (error) {
    console.error("Error fetching yearly resolution rate data:", error);
    return [];
  }

  // Group data by month
  const monthResolutionMap = {};
  data.forEach((record) => {
    const date = new Date(record.created_at);
    const month = date.toLocaleString("en-US", { month: "short" });
    if (!monthResolutionMap[month]) {
      monthResolutionMap[month] = { resolved: 0, total: 0 };
    }
    monthResolutionMap[month].total += 1;
    if (record.is_resolved) {
      monthResolutionMap[month].resolved += 1;
    }
  });

  // Prepare data for chart
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const result = monthsOfYear.map((month) => ({
    month,
    rate: monthResolutionMap[month]
      ? (
          (monthResolutionMap[month].resolved /
            monthResolutionMap[month].total) *
          100
        ).toFixed(1)
      : 0,
  }));

  return result;
}
