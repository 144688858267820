import React, { useState } from "react";

const CollapsibleSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="mb-2 border border-gray-300 rounded-lg overflow-hidden shadow-sm">
      <button
        className="w-full text-left font-semibold py-2 px-4 bg-gray-100 hover:bg-gray-200 flex justify-between items-center text-gray-700"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{title}</span>
        <span>{isExpanded ? "▲" : "▼"}</span>
      </button>
      {isExpanded && (
        <div className="p-4 bg-white border-t border-gray-200">{children}</div>
      )}
    </div>
  );
};

const PatientInfo = ({ appointment }) => {
  if (!appointment) {
    return (
      <aside className="bg-white rounded-lg shadow-md p-4 text-center text-gray-600 flex-grow">
        <p>Select an appointment to view details</p>
      </aside>
    );
  }

  // Extract patient information
  const getPatientName = () =>
    appointment.patient_name ||
    `${appointment.patient?.firstName || ""} ${
      appointment.patient?.lastName || ""
    }`.trim() ||
    "Unknown";

  const getPhoneNumber = () =>
    appointment.phone_number ||
    appointment.patient?.cellPhone_number ||
    "Unknown";

  const getAddress = () =>
    appointment.patient?.address_line1
      ? `${appointment.patient.address_line1}, ${appointment.patient.address_city}, ${appointment.patient.address_countrySubdivision} ${appointment.patient.address_postal}`
      : "Unknown";

  const getEmail = () => appointment.patient?.email || "Unknown";

  const getDateOfBirth = () =>
    appointment.DoB || appointment.patient?.dateOfBirth || "Unknown";

  const getInsuranceProvider = () => appointment.insuranceProvider || "Unknown";

  const getPerioCharting = () => appointment.perioCharting || "Unknown";

  return (
    <aside className="bg-white rounded-lg shadow-lg p-6 overflow-y-auto border border-gray-200 flex-grow h-full">
      <div className="mb-2 text-center">
        <h2 className="text-lg font-bold text-gray-800">{getPatientName()}</h2>
      </div>

      <CollapsibleSection title="Patient Information">
        <p className="text-sm mb-1">
          <strong>Phone:</strong> {getPhoneNumber()}
        </p>
        <p className="text-sm mb-1">
          <strong>Address:</strong> {getAddress()}
        </p>
        <p className="text-sm mb-1">
          <strong>Email:</strong> {getEmail()}
        </p>
        <p className="text-sm mb-1">
          <strong>Date of Birth:</strong> {getDateOfBirth()}
        </p>
        <p className="text-sm mb-1">
          <strong>Insurance:</strong> {getInsuranceProvider()}
        </p>
      </CollapsibleSection>

      <CollapsibleSection title="Appointment Information">
        <p className="text-sm mb-1">
          <strong>Start Time:</strong>{" "}
          {appointment.startTime
            ? new Date(appointment.startTime).toLocaleString("en-US", {
                timeZone: appointment.timezone,
              })
            : "Unknown"}
        </p>
        <p className="text-sm mb-1">
          <strong>Duration:</strong>{" "}
          {appointment.length ? `${appointment.length} minutes` : "Unknown"}
        </p>
        <p className="text-sm mb-1">
          <strong>Status:</strong> {appointment.appointmentStatus || "Unknown"}
        </p>
        <p className="text-sm mb-1">
          <strong>Confirmation:</strong>{" "}
          {appointment.confirmation || "No Confirmation"}
        </p>
        <p className="text-sm mb-1">
          <strong>Created At:</strong>{" "}
          {appointment.createdAt
            ? new Date(appointment.createdAt).toLocaleString()
            : "Unknown"}
        </p>
        <p className="text-sm mb-1">
          <strong>Booked Online:</strong>{" "}
          {appointment.isBookedOnline === "true" ? "Yes" : "No"}
        </p>
        <p className="text-sm mb-1">
          <strong>Appointment Type:</strong> {appointment.kind || "Unknown"}
        </p>
        <p className="text-sm mb-1">
          <strong>Operatory:</strong> {appointment.operatoryId || "Unknown"}
        </p>
      </CollapsibleSection>

      {/* <CollapsibleSection title="Period Charting">
        <p className="text-sm">{getPerioCharting()}</p>
      </CollapsibleSection> */}
    </aside>
  );
};

export default PatientInfo;
