// Home.js
import React from "react";
import TitleSection from "./components/TitleSection";
import ExploreSolution from "./components/exploreSolutionHome";
import TestimonialHome from "./components/testimonialHome";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import Features from "./components/Features";
import HowitWorks from "./HowitWorks";
import ModernContactForm from "./components/ModernContactForm";

function Home({ refs }) {
  const { privacyRef, testimonialRef, faqRef, featureRef, howitworksRef } =
    refs;

  return (
    <div>
      <TitleSection />
      <ModernContactForm />
      {/* <div className="my-0 border-b border-gray-300"></div> */}
      <Features ref={featureRef} />
      <ExploreSolution ref={privacyRef} />
      {/* <div className="my-0 border-b border-gray-300"></div> */}

      <HowitWorks ref={howitworksRef} />
      {/* <hr /> */}
      {/* <TestimonialHome ref={testimonialRef} /> */}
      <FAQ ref={faqRef} />

      <Footer />
    </div>
  );
}

export default Home;
