import React, { useState, useEffect } from "react";
import {
  fetchUniqueSession,
  fetchMessagesBySessionId,
  fetchSessionSummary,
  markSessionAsRead,
} from "../backend/supabaseService";
import MessageConversation from "./MessageConversation";

const CallHistory = () => {
  const [uniqueSessions, setUniqueSessions] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [summary, setSummary] = useState("");
  const [isViewingMessages, setIsViewingMessages] = useState(false);

  // Fetch unique sessions
  useEffect(() => {
    const getSessions = async () => {
      const sessions = await fetchUniqueSession();
      setUniqueSessions(sessions);
    };

    getSessions();
  }, []);

  // Fetch messages and summary when a session is selected
  useEffect(() => {
    const getMessagesAndSummary = async () => {
      if (!selectedSessionId) return;

      const messages = await fetchMessagesBySessionId(selectedSessionId);
      setMessages(messages);

      const summary = await fetchSessionSummary(selectedSessionId);
      setSummary(summary);

      // Mark session as read
      await markSessionAsRead(selectedSessionId);

      // Update local state to reflect read status
      setUniqueSessions((prevSessions) =>
        prevSessions.map((session) =>
          session.sessionId === selectedSessionId
            ? { ...session, is_read: true }
            : session
        )
      );
    };

    if (isViewingMessages) {
      getMessagesAndSummary();
    }
  }, [selectedSessionId, isViewingMessages]);

  // Handle session click to view messages
  const handleSessionClick = (sessionId) => {
    setSelectedSessionId(sessionId);
    setIsViewingMessages(true);
  };

  // Handle back button to return to call history list
  const handleBack = () => {
    setIsViewingMessages(false);
    setSelectedSessionId(null);
    setMessages([]);
    setSummary("");
  };

  return (
    <div className="h-full">
      {!isViewingMessages ? (
        <div className="overflow-y-auto flex-grow p-4">
          {uniqueSessions.length > 0 ? (
            uniqueSessions.map((session) => (
              <div
                key={session.sessionId}
                onClick={() => handleSessionClick(session.sessionId)}
                className="cursor-pointer p-4 mb-2 rounded-lg bg-white hover:bg-gray-100 shadow-md flex items-center justify-between"
                title={`Full Session ID: ${session.sessionId}`}
              >
                <div>
                  <p className="text-sm font-medium text-gray-800">
                    ID: {session.truncatedId}
                  </p>
                  <p className="text-xs text-gray-500">
                    {session.date} {session.time}
                  </p>
                </div>
                {!session.is_read && (
                  <div className="w-3 h-3 bg-blue-500 rounded-full ml-2 shadow-md" />
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-500">No sessions found.</p>
          )}
        </div>
      ) : (
        <div className="flex flex-col h-full">
          <div className="flex items-center p-4 bg-gray-100">
            <button
              onClick={handleBack}
              className="flex items-center text-blue-500 hover:text-blue-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 3.293a1 1 0 010 1.414L7.414 9H17a1 1 0 110 2H7.414l4.879 4.879a1 1 0 11-1.414 1.414l-6.586-6.586a1 1 0 010-1.414l6.586-6.586a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Back
            </button>
          </div>
          <div className="overflow-y-auto flex-grow p-4">
            {summary && (
              <div className="mb-4 p-4 bg-purple-50 rounded-lg shadow-md border-l-4 border-purple-400">
                <h3 className="text-base font-semibold text-purple-800 mb-1">
                  AI Summary
                </h3>
                <p className="text-sm italic text-gray-800">{summary}</p>
              </div>
            )}
            {messages.length > 0 ? (
              messages.map((message) => (
                <MessageConversation key={message.id} message={message} />
              ))
            ) : (
              <p className="text-gray-500">
                No conversations found for this session.
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CallHistory;
