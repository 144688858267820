import React from "react";
import { useNavigate } from "react-router-dom";

const FAQ = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  return (
    <div ref={ref}>
      <div class="container my-10 mx-auto md:px-6">
        <section class="mb-32">
          <h2 class="mb-16 text-left text-3xl font-extrabold">FAQs</h2>

          <div class="grid gap-6 lg:grid-cols-3">
            <div class="mb-6 md:mb-8 lg:mb-12">
              <p class="mb-4 font-bold">What is operaitor?</p>
              <p class="text-neutral-500 dark:text-neutral-300">
                Operaitor is a HIPAA-compliant answering service that helps
                healthcare clinics streamline operations and enhance patient
                care. Our AI-powered technology acts as an extension of your
                team, answering calls 24/7, scheduling appointments, and
                handling routine inquiries.
              </p>
            </div>

            <div class="mb-6 md:mb-8 lg:mb-12">
              <p class="mb-4 font-bold">How can Operaitor benefit my clinic?</p>
              <p class="text-neutral-500 dark:text-neutral-300">
                We ensure patients always reach a friendly voice, improving
                accessibility and satisfaction. We also free up your staff to
                focus on patient care by while improving scheduling accuracy,
                minimizing wait times for patients.
              </p>
            </div>

            <div class="mb-6 md:mb-8 lg:mb-12">
              <p class="mb-4 font-bold">
                Is Operaitor compatible with existing PMS system?
              </p>
              <p class="text-neutral-500 dark:text-neutral-300">
                Many PMS systems offer APIs that allow for smooth data flow
                between our service and your PMS. If your PMS doesn't have a
                direct API, we may be able to develop alternative solutions to
                minimize manual data entry, such as secure file transfer or
                custom data import options.
              </p>
            </div>

            <div class="mb-6 md:mb-8 lg:mb-12">
              <p class="mb-4 font-bold">
                How do patients make appointments using Operaitor?
              </p>
              <p class="text-neutral-500 dark:text-neutral-300">
                They can simply call our dedicated answering line 24/7 and speak
                to our friendly Operaitor Agent. Based on the plan, there is
                also an option to text!
              </p>
            </div>

            <div class="mb-6 md:mb-8 lg:mb-12">
              <p class="mb-4 font-bold">
                {" "}
                How do I get started with Operaitor?
              </p>
              <p class="text-neutral-500 dark:text-neutral-300">
                Simply contact our team through our email or give us a call! o,
                and we'll guide you through the setup process. You can also
                request a demo or free trial to see how our software works for
                your restaurant.
              </p>
            </div>

            <div class="mb-6 md:mb-8 lg:mb-12">
              <p class="mb-4 font-bold">
                Does Operaitor support other languages?
              </p>
              <p class="text-neutral-500 dark:text-neutral-300">
                Yes! One of the best features about using Operaitor is its
                multi-lingual support, empowering you engage with customers
                across languages.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
});

export default FAQ;
