import React, { useState } from "react";
import { liveDemoTry } from "../backend/supabaseService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactAgent = () => {
  const [formData, setFormData] = useState({
    phone: "",
    name: "",
    email: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { phone, name, email } = formData;

    if (!phone || !name || !email) {
      toast.error("Please fill out all fields.");
      return;
    }

    try {
      const data = await liveDemoTry(phone, name, email);
      if (data) {
        // Show success toast
        toast.success("You will receive a call soon!");
        // Optionally reset the form
        setFormData({ phone: "", name: "", email: "" });
      } else {
        console.error("Failed to insert data.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("There was an error. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center py-12 w-full">
      <div className="w-full max-w-5xl p-4">
        <h2 className="text-3xl font-bold mb-8 text-center"></h2>
        <div className="flex justify-center">
          {/* Form Section */}
          <div className="bg-gray-900 text-white rounded-lg p-6 shadow-lg w-full">
            <div className="mb-6">
              <h3 className="text-xl text-cyan-400 font-semibold text-center">
                Let our AI call you, and try booking an appointment.
              </h3>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block mb-2">Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="(555) 123-4567"
                  className="w-full p-2 rounded bg-gray-800 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  required
                />
              </div>
              <div>
                <label className="block mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Your name"
                  className="w-full p-2 rounded bg-gray-800 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  required
                />
              </div>
              <div>
                <label className="block mb-2">Email Address</label>
                <input
                  type="email"
                  name="email"
                  placeholder="you@example.com"
                  className="w-full p-2 rounded bg-gray-800 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-cyan-500 hover:bg-cyan-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
              >
                Get a Call
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactAgent;
