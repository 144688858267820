import React, { useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { liveDemoTry } from "../backend/supabaseService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModernContactForm = () => {
  const [formData, setFormData] = useState({
    phone: "",
    name: "",
    email: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { phone, name, email } = formData;
    if (!phone || !name || !email) {
      toast.error("Please fill out all fields.");
      return;
    }
    try {
      const data = await liveDemoTry(phone, name, email);
      if (data) {
        // Show success toast
        toast.success("You will receive a call soon!");
        // Optionally reset the form
        setFormData({ phone: "", name: "", email: "" });
      } else {
        console.error("Failed to insert data.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("There was an error. Please try again.");
    }
  };

  return (
    <div className="w-full h-auto bg-white py-6 px-4 mb-10">
      <div className="max-w-6xl mx-auto grid md:grid-cols-3 gap-8 items-center">
        {/* Left Image - Receptionist with Phone */}
        <div className="hidden md:block">
          <svg
            className="w-full h-auto"
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(30, 20)">
              {/* Desk */}
              <rect
                x="0"
                y="130"
                width="140"
                height="20"
                fill="#5bc1d5"
                rx="5"
              />
              {/* Receptionist Body */}
              <circle cx="70" cy="70" r="30" fill="#ffd1dc" />
              {/* Receptionist Hair */}
              <path
                d="M40,60 C40,40 100,40 100,60 C100,80 40,80 40,60 Z"
                fill="#333"
              />
              {/* Eyes */}
              <circle cx="60" cy="70" r="3" fill="#fff" />
              <circle cx="80" cy="70" r="3" fill="#fff" />
              {/* Mouth */}
              <path
                d="M60,80 Q70,90 80,80"
                stroke="#e57373"
                strokeWidth="2"
                fill="none"
              />

              <g transform="translate(100, 60) rotate(-45)">
                {/* Handle */}
                <rect x="0" y="0" width="40" height="8" fill="#26add0" rx="4" />
                {/* Bristles */}
                <rect
                  x="30"
                  y="-2"
                  width="15"
                  height="12"
                  fill="#ffffff"
                  rx="2"
                />
                <line
                  x1="32"
                  y1="-2"
                  x2="32"
                  y2="10"
                  stroke="#eee"
                  strokeWidth="1"
                />
                <line
                  x1="35"
                  y1="-2"
                  x2="35"
                  y2="10"
                  stroke="#eee"
                  strokeWidth="1"
                />
                <line
                  x1="38"
                  y1="-2"
                  x2="38"
                  y2="10"
                  stroke="#eee"
                  strokeWidth="1"
                />
                <line
                  x1="41"
                  y1="-2"
                  x2="41"
                  y2="10"
                  stroke="#eee"
                  strokeWidth="1"
                />
              </g>
              {/* Toothpaste */}

              <g transform="translate(85, 100)">
                {/* Main Tube Body - using path for curved shape */}
                <path
                  d="M0,0 
           C5,-2 35,-2 40,0
           L38,25
           C35,27 5,27 2,25 Z"
                  fill="#5bc1d5"
                />
                {/* Tube Details */}
                <path
                  d="M5,5
           C8,3 32,3 35,5
           L34,20
           C31,22 9,22 6,20 Z"
                  fill="#ffffff"
                  opacity="0.2"
                />
                {/* Cap */}
                <path
                  d="M15,-5 
           L25,-5 
           L23,-2
           L17,-2 Z"
                  fill="#ffffff"
                />
                {/* Brand Stripe */}
                <path
                  d="M10,8
           C12,7 28,7 30,8
           L30,12
           C28,13 12,13 10,12 Z"
                  fill="#ffffff"
                  opacity="0.5"
                />
              </g>
            </g>
          </svg>
        </div>

        {/* Center Form */}
        <div className="md:col-span-1 bg-gray-800 p-10 rounded-3xl shadow-lg shadow-gray-700">
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-white mb-2">
              Don't believe us?
            </h2>
            <p className="text-gray-300">
              Let our AI call you, and try booking a service appointment.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Your name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="w-full px-4 py-2 rounded-lg border border-gray-500 bg-gray-700 text-white placeholder-gray-400 focus:ring-2 focus:ring-[#26add0] focus:border-transparent outline-none transition"
                placeholder="John Smith"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Your number <span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                className="w-full px-4 py-2 rounded-lg border border-gray-500 bg-gray-700 text-white placeholder-gray-400 focus:ring-2 focus:ring-[#26add0] focus:border-transparent outline-none transition"
                placeholder="(123) 456-7890"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Your email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                className="w-full px-4 py-2 rounded-lg border border-gray-500 bg-gray-700 text-white placeholder-gray-400 focus:ring-2 focus:ring-[#26add0] focus:border-transparent outline-none transition"
                placeholder="john.smith@example.com"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>

            <button
              type="submit"
              className="w-full bg-[#26add0] hover:bg-[#5bc1d5] text-white font-semibold py-3 px-4 rounded-lg shadow-md transition-all duration-200 flex items-center justify-center gap-2"
            >
              <PhoneIcon />
              Call me now
            </button>
          </form>
        </div>

        {/* Right Image - Modern Smartphone with Chat Bubbles */}
        <div className="hidden md:block">
          <svg
            className="w-full h-auto"
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(50, 20)">
              {/* Smartphone Body */}
              <rect x="10" y="10" width="80" height="140" rx="15" fill="#333" />
              {/* Screen */}
              <rect x="18" y="25" width="64" height="110" rx="10" fill="#fff" />
              {/* Chat Bubble 1 */}
              <path
                d="M30,40 h40 a5,5 0 0 1 5,5 v15 a5,5 0 0 1 -5,5 h-30 l-10,10 v-25 a5,5 0 0 1 5,-5 z"
                fill="#5bc1d5"
              />
              {/* Chat Bubble 2 */}
              <path
                d="M30,80 h30 a5,5 0 0 1 5,5 v15 a5,5 0 0 1 -5,5 h-40 l-10,10 v-25 a5,5 0 0 1 5,-5 z"
                fill="#26add0"
              />
              {/* Signal Icon */}
              <circle cx="50" cy="145" r="4" fill="#26add0" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ModernContactForm;
