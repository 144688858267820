import React from "react";

const MessageConversation = ({ message }) => {
  const isUser = message.role === "user";

  return (
    <div
      className={`flex mb-4 items-end ${
        isUser ? "justify-end" : "justify-start"
      }`}
    >
      <div
        className={`max-w-[70%] px-4 py-2 rounded-lg ${
          isUser
            ? "bg-blue-500 text-white rounded-br-none"
            : "bg-gray-200 text-gray-800 rounded-bl-none"
        }`}
      >
        {/* Adjust the property name based on the actual data */}
        <p className="text-sm">
          {message.content
            ? message.content
            : message.message
            ? message.message
            : "No content"}
        </p>
      </div>
    </div>
  );
};

export default MessageConversation;
