import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { fetchAppointments } from "../backend/supabaseService";
import { StatusEditCell } from "../components/StatusEditCell";
import { createClient } from "@supabase/supabase-js";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const GreenDot = styled("span")({
  height: 10,
  width: 10,
  backgroundColor: "green",
  borderRadius: "50%",
  display: "inline-block",
  marginRight: 8,
});

const OrangeDot = styled("span")({
  height: 10,
  width: 10,
  backgroundColor: "orange",
  borderRadius: "50%",
  display: "inline-block",
  marginRight: 8,
});

const RedDot = styled("span")({
  height: 10,
  width: 10,
  backgroundColor: "red",
  borderRadius: "50%",
  display: "inline-block",
  marginRight: 8,
});

function StatusCell(params) {
  let Dot;
  let color;

  // Ensure `params.value` is correctly mapped to display all possible statuses
  switch (params.value) {
    case "Check In":
      Dot = GreenDot;
      color = "green";
      break;
    case "Active":
      Dot = GreenDot;
      color = "green";
      break;
    case "Cancel":
      Dot = RedDot;
      color = "red";
      break;
    case "Delete":
      Dot = RedDot;
      color = "red";
      break;
    case "Check Out":
      Dot = OrangeDot;
      color = "orange";
      break;
    case "Missed":
      Dot = OrangeDot;
      color = "orange";
      break;
    default:
      Dot = null;
      color = "inherit";
  }

  return (
    <div>
      {Dot && <Dot />}
      <span style={{ color: color }}>{params.value || "Scheduled"}</span>
    </div>
  );
}

function formatTime(dateString) {
  const date = new Date(dateString);
  // Convert to Eastern Time (ET) using toLocaleString with the New York time zone
  const estTime = date.toLocaleString("en-US", {
    timeZone: "America/New_York",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return estTime;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const columns = [
  { field: "id", headerName: "ID", width: 90, editable: false },
  {
    field: "patient_name",
    headerName: "Patient Name",
    width: 150,
    editable: true,
  },
  {
    field: "phone_number",
    headerName: "Phone Number",
    width: 150,
    editable: true,
  },
  { field: "date", headerName: "Appointment Date", width: 150, editable: true },
  { field: "time", headerName: "Appointment Time", width: 130, editable: true },
  { field: "DoB", headerName: "DoB", width: 130, editable: true },
  {
    field: "appointment_status",
    headerName: "Appointment Status",
    width: 150,
    renderCell: StatusCell,
    editable: true,
    renderEditCell: (params) => <StatusEditCell {...params} />,
  },
  {
    field: "confirmation_status",
    headerName: "Confirmation Status",
    width: 150,
    renderCell: StatusCell,
    editable: true,
  },
  {
    field: "treatment_type",
    headerName: "Description",
    width: 180,
    editable: true,
  },
];

async function updateAppointment(id, updatedFields) {
  const { data, error } = await supabase
    .from("appointments")
    .update(updatedFields)
    .eq("id", id);

  if (error) {
    throw new Error(error.message);
  }
  return data;
}

function AppointmentPage() {
  const [appointments, setAppointments] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    async function loadAppointments() {
      const data = await fetchAppointments();
      console.log("data for appintmentPage: ", data);

      const formattedAppointments = data.map((appointment) => {
        if (appointment.source === "curveDental") {
          return {
            date: formatDate(new Date(appointment.startTime)),
            patient_name: `${appointment.patient.firstName} ${appointment.patient.lastName}`,
            id: appointment.id,
            phone_number: appointment.patient.cellPhone_number,
            confirmation_status: appointment.confirmation,
            treatment_type: appointment.description,
            provider: appointment.provider_name,
            readOnly: true,
            source: "curveDental",
            patient: appointment.patient,
            DoB: appointment.patient.dob,
            time: formatTime(appointment.startTime),
            appointment_status: appointment.appointmentStatus,
          };
        }
        return appointment;
      });
      setAppointments(formattedAppointments);
    }
    loadAppointments();
  }, []);

  const filteredRows = appointments.filter((appointment) => {
    const matchesDate =
      !selectedDate || appointment.date === formatDate(selectedDate);
    const matchesStatus =
      !selectedStatus || appointment.appointment_status === selectedStatus;
    return matchesDate && matchesStatus;
  });

  const handleProcessRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow };
    try {
      await updateAppointment(newRow.id, {
        patient_name: newRow.patient_name,
        phone_number: newRow.phone_number,
        date: newRow.date,
        time: newRow.time,
        DoB: newRow.DoB,
        confirmation_status: newRow.confirmation_status,
        treatment_type: newRow.treatment_type,
        appointment_status: newRow.appointmentStatus,
      });
      const updatedAppointments = appointments.map((appointment) =>
        appointment.id === newRow.id ? updatedRow : appointment
      );
      setAppointments(updatedAppointments);
      toast.success("Appointment updated successfully!");
    } catch (error) {
      toast.error("Failed to update appointment. Please try again.");
      console.error("Error updating appointment:", error);
    }
    return updatedRow;
  };

  return (
    <Box sx={{ height: 520, width: "100%" }}>
      <Box sx={{ mt: 3, mb: 2, display: "flex", alignItems: "center" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel>Appointment Status</InputLabel>
            <Select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              label="Appointment Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Check In">Check In</MenuItem>
              <MenuItem value="Cancel">Cancel</MenuItem>
              <MenuItem value="Missed">Missed</MenuItem>
              <MenuItem value="Check Out">Check Out</MenuItem>
              <MenuItem value="Cut">Cut</MenuItem>
              <MenuItem value="Delete">Delete</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      {filteredRows.length === 0 ? (
        <Typography variant="h6" align="center" sx={{ mt: 4 }}>
          No appointments available for the selected date or status
        </Typography>
      ) : (
        <DataGrid
          rows={filteredRows.map((appointment) => ({
            id: appointment.id,
            patient_name: appointment.patient_name,
            phone_number: appointment.phone_number,
            date: appointment.date,
            time: appointment.time,
            DoB: appointment.DoB,
            treatment_type: appointment.treatment_type,
            confirmation_status: appointment.confirmation_status,
            appointment_status: appointment.appointment_status,
          }))}
          columns={columns}
          loading={filteredRows.length === 0}
          rowHeight={38}
          checkboxSelection
          disableRowSelectionOnClick
          processRowUpdate={handleProcessRowUpdate}
        />
      )}
      <ToastContainer />
    </Box>
  );
}

export default AppointmentPage;
