import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChatIcon from "@mui/icons-material/Chat";
import RestoreIcon from "@mui/icons-material/Restore";
import { useNavigate } from "react-router-dom";

const Features = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const features = [
    {
      icon: (
        <PhoneIcon
          fontSize="inherit"
          style={{ fontSize: "3rem", color: "#26add0" }}
        />
      ),
      title: "AI Phone Answering",
      description:
        "We handle inbound and outbound calls 24/7, covering appointment scheduling, patient inquiries, and more.",
    },
    {
      icon: (
        <EventAvailableIcon
          fontSize="inherit"
          style={{ fontSize: "3rem", color: "#26add0" }}
        />
      ),
      title: "Appointment Scheduling",
      description:
        "Seamlessly book appointments for patients, manage schedules, and handle changes.",
    },
    {
      icon: (
        <HelpOutlineIcon
          fontSize="inherit"
          style={{ fontSize: "3rem", color: "#26add0" }}
        />
      ),
      title: "Insurance Inquiries",
      description:
        "Provide Tier 1 support for insurance questions, ensuring patients have the info they need.",
    },
    {
      icon: (
        <AccessTimeIcon
          fontSize="inherit"
          style={{ fontSize: "3rem", color: "#26add0" }}
        />
      ),
      title: "After-Hours Support",
      description:
        "Our AI handles calls after hours, offering consistent support even when your team is unavailable.",
    },
    {
      icon: (
        <ChatIcon
          fontSize="inherit"
          style={{ fontSize: "3rem", color: "#26add0" }}
        />
      ),
      title: "Texting & Chatbot",
      description:
        "Engage patients through text and web widgets for quick answers and appointment confirmations.",
    },
    {
      icon: (
        <RestoreIcon
          fontSize="inherit"
          style={{ fontSize: "3rem", color: "#26add0" }}
        />
      ),
      title: "Recall for No-Shows",
      description:
        "Follow up with patients who missed appointments, helping them reschedule conveniently.",
    },
  ];

  return (
    <section className="features-home-section py-2 px-4" ref={ref}>
      <h2 className="text-center text-3xl font-extrabold mb-8">
        Operaitor Use Cases
      </h2>
      <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-4">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-6 bg-white border border-gray-200 rounded-lg shadow-lg h-64 w-full"
          >
            {feature.icon}
            <h3 className="text-2xl font-bold mt-2">{feature.title}</h3>
            <p className="text-gray-600 text-center mt-2 text-base">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
});

export default Features;
