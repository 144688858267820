import React, { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import Avatar from "react-avatar";
import operaitorEmoji from "../assets/1024px.png";

// Create a Supabase client instance
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Utility functions
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};

const truncateSessionId = (sessionId) => {
  return sessionId.substring(0, 8) + "...";
};

// Message component
const Message = ({ message }) => {
  const isAssistant = message.role === "assistant";
  return (
    <div
      className={`flex items-start gap-2.5 mb-3 ${
        isAssistant ? "justify-end" : "justify-start"
      }`}
    >
      <Avatar
        name={isAssistant ? "AI Assistant" : "User"}
        src={isAssistant ? operaitorEmoji : null}
        round={true}
        size="40"
        className="w-8 h-8"
      />
      <div
        className={`flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 
        rounded-e-xl rounded-es-xl ${
          isAssistant
            ? "bg-blue-100 dark:bg-blue-700"
            : "bg-gray-100 dark:bg-gray-700"
        }`}
      >
        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          <span className="text-sm font-semibold text-gray-900 dark:text-white">
            {isAssistant ? "Assistant" : "User"}
          </span>
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {new Date(message.created_at).toLocaleTimeString()}
          </span>
        </div>
        <p className="text-sm font-normal py-1 text-gray-900 dark:text-white">
          {message.message}
        </p>
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {message.status}
        </span>
      </div>
    </div>
  );
};

function MessagePage() {
  const [messages, setMessages] = useState([]);
  const [uniqueSessions, setUniqueSessions] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [summary, setSummary] = useState(""); // New state for the summary

  useEffect(() => {
    const fetchUniqueSessions = async () => {
      try {
        const { data, error } = await supabase
          .from("conversationLog")
          .select("sessionId, created_at, is_read")
          .order("created_at", { ascending: false });

        if (error) throw error;

        const sessionsMap = new Map();
        data.forEach((entry) => {
          if (!sessionsMap.has(entry.sessionId)) {
            sessionsMap.set(entry.sessionId, {
              created_at: entry.created_at,
              is_read: entry.is_read,
            });
          }
        });

        const uniqueSessionsArray = Array.from(
          sessionsMap,
          ([sessionId, { created_at, is_read }]) => ({
            sessionId,
            truncatedId: truncateSessionId(sessionId),
            date: formatDate(created_at),
            time: new Date(created_at).toLocaleTimeString(),
            is_read,
          })
        );

        setUniqueSessions(uniqueSessionsArray);
        if (uniqueSessionsArray.length > 0) {
          setSelectedSessionId(uniqueSessionsArray[0].sessionId);
        }
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    };

    fetchUniqueSessions();
  }, []);

  useEffect(() => {
    const fetchMessagesAndSummary = async () => {
      if (!selectedSessionId) return;

      try {
        // Fetch messages
        const { data: messagesData, error: messagesError } = await supabase
          .from("conversationLog")
          .select("*")
          .eq("sessionId", selectedSessionId)
          .order("created_at", { ascending: true });

        if (messagesError) throw messagesError;
        setMessages(messagesData);

        // Fetch summary for the selected session
        const { data: summaryData, error: summaryError } = await supabase
          .from("conversationLog")
          .select("summary")
          .eq("sessionId", selectedSessionId)
          .limit(1) // Only need one record to get the summary
          .single();

        if (summaryError) throw summaryError;
        setSummary(summaryData?.summary || ""); // Update the summary state

        // Mark the session as read
        await supabase
          .from("conversationLog")
          .update({ is_read: true })
          .eq("sessionId", selectedSessionId);

        // Update the local state to reflect the change
        setUniqueSessions((prevSessions) =>
          prevSessions.map((session) =>
            session.sessionId === selectedSessionId
              ? { ...session, is_read: true }
              : session
          )
        );
      } catch (error) {
        console.error("Error fetching messages or summary:", error);
      }
    };

    fetchMessagesAndSummary();
  }, [selectedSessionId]);

  return (
    <div className="flex h-screen">
      {/* Left Sidebar */}
      <div className="w-1/4 bg-gray-50 dark:bg-gray-800 flex flex-col h-full">
        <h2 className="text-lg font-bold text-gray-900 dark:text-white p-4">
          Call History
        </h2>
        <div className="overflow-y-auto flex-grow">
          {uniqueSessions.length > 0 ? (
            uniqueSessions.map((session) => (
              <div
                key={session.sessionId}
                onClick={() => setSelectedSessionId(session.sessionId)}
                className={`cursor-pointer p-2 m-2 rounded-lg flex items-center justify-between ${
                  selectedSessionId === session.sessionId
                    ? "bg-blue-200 dark:bg-blue-700"
                    : "bg-white dark:bg-gray-700"
                }`}
                title={`Full Session ID: ${session.sessionId}`}
              >
                <div>
                  <p className="text-sm font-medium text-gray-800 dark:text-white">
                    ID: {session.truncatedId}
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {session.date} {session.time}
                  </p>
                </div>
                {!session.is_read && (
                  <div className="w-3 h-3 bg-blue-500 dark:bg-blue-400 rounded-full ml-2 shadow-md" />
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-500 dark:text-gray-400 p-4">
              No sessions found.
            </p>
          )}
        </div>
      </div>

      {/* Right Panel */}
      <div className="flex-1 p-4 overflow-y-auto">
        {selectedSessionId ? (
          <>
            {/* AI Summary Section */}
            {summary && (
              <div className="mb-4 p-6 bg-purple-50 dark:bg-purple-800 rounded-lg shadow-md border-l-4 border-purple-400 dark:border-purple-500">
                <div className="flex items-center mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-purple-600 dark:text-purple-300 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13 16h-1v-4h-.01M12 9h.01m0-2.648c-4.282 0-7.723 3.243-7.723 7.226a7.226 7.226 0 1014.453 0c0-3.982-3.44-7.226-7.73-7.226z"
                    />
                  </svg>
                  <h3 className="text-lg font-semibold text-purple-800 dark:text-purple-200">
                    AI Summary
                  </h3>
                </div>
                <p className="text-md italic text-gray-800 dark:text-gray-200">
                  {summary}
                </p>
              </div>
            )}

            {messages.length > 0 ? (
              messages.map((message) => (
                <Message key={message.id} message={message} />
              ))
            ) : (
              <p className="text-gray-500 dark:text-gray-400">
                No conversations found for this session.
              </p>
            )}
          </>
        ) : (
          <p className="text-gray-500 dark:text-gray-400">
            Select a session to view conversations.
          </p>
        )}
      </div>
    </div>
  );
}

export default MessagePage;
