import React, { useState, useEffect } from "react";
import ScheduleGrid from "../components/ScheduleGrid";
import PatientInfo from "../components/PatientInfo";
import { fetchAppointments } from "../backend/supabaseService";
import CallHistory from "../components/CallHistory";

const DentalScheduler = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [view, setView] = useState("day");
  const [appointments, setAppointments] = useState([]);
  const [activeTab, setActiveTab] = useState("Call History");

  useEffect(() => {
    const fetchAppointmentsData = async () => {
      const data = await fetchAppointments();
      console.log("Fetched appointments:", data);
      setAppointments(data);
    };

    fetchAppointmentsData();
  }, []);

  const navigateDate = (direction) => {
    const newDate = new Date(selectedDate);
    if (view === "day") {
      newDate.setDate(newDate.getDate() + direction);
    } else {
      newDate.setDate(newDate.getDate() + direction * 7);
    }
    setSelectedDate(newDate);
  };

  // New function to handle selecting an appointment
  const handlePatientSelect = (appointment) => {
    setSelectedAppointment(appointment);
    setActiveTab("Patient Info"); // Switch to Patient Info tab on appointment selection
  };

  return (
    <div className="bg-gray-100 p-4 h-screen flex flex-col">
      {/* Header */}
      <header className="flex justify-between items-center mb-4">
        {/* User Info */}
        <div className="flex items-center space-x-4">
          <img
            src="profile-pic.jpg"
            alt="Wanho Lew"
            className="w-12 h-12 rounded-full"
          />
          <div>
            <h1 className="text-2xl font-bold">Wanho Lew</h1>
            <span className="text-gray-600">Line Dental</span>
          </div>
        </div>
        {/* Navigation */}
        <div className="flex space-x-2 items-center">
          <button
            onClick={() => navigateDate(-1)}
            className="px-3 py-1 bg-blue-500 text-white rounded"
          >
            &lt; Prev
          </button>
          <span className="font-bold text-lg">
            {selectedDate.toLocaleDateString()}
          </span>
          <button
            onClick={() => navigateDate(1)}
            className="px-3 py-1 bg-blue-500 text-white rounded"
          >
            Next &gt;
          </button>
          <button
            className={`px-3 py-1 ${
              view === "week" ? "bg-blue-700" : "bg-blue-500"
            } text-white rounded`}
            onClick={() => setView("week")}
          >
            Week
          </button>
          <button
            className={`px-3 py-1 ${
              view === "day" ? "bg-blue-700" : "bg-blue-500"
            } text-white rounded`}
            onClick={() => setView("day")}
          >
            Day
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex flex-1 gap-4 overflow-hidden h-[calc(100vh-7rem)]">
        <div className="flex-1 min-h-0">
          <ScheduleGrid
            date={selectedDate}
            onPatientSelect={handlePatientSelect} // Use the new handler here
            view={view}
            appointments={appointments}
          />
        </div>

        {/* Right Panel with Tabs */}
        <div className="w-1/4 bg-white rounded-lg shadow-md flex flex-col">
          {/* Tab Navigation */}
          <div className="flex border-b border-gray-200">
            <button
              className={`flex-1 py-2 px-4 text-center ${
                activeTab === "Call History"
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveTab("Call History")}
            >
              Call History
            </button>
            <button
              className={`flex-1 py-2 px-4 text-center ${
                activeTab === "Patient Info"
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveTab("Patient Info")}
            >
              Patient Info
            </button>
          </div>

          {/* Tab Content */}
          <div className="flex-1 overflow-y-auto">
            {activeTab === "Patient Info" ? (
              <PatientInfo appointment={selectedAppointment} />
            ) : (
              <CallHistory />
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default DentalScheduler;
