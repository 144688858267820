import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

export async function fetchPhone(cellPhoneId, homePhoneId) {
  const phoneMap = {};

  if (cellPhoneId) {
    const { data: cellPhoneData, error: cellPhoneError } = await supabase
      .from("curve_phone")
      .select("number")
      .eq("id", cellPhoneId);
    if (!cellPhoneError && cellPhoneData) {
      phoneMap[cellPhoneId] = cellPhoneData[0]?.number;
    }
  }

  if (homePhoneId) {
    const { data: homePhoneData, error: homePhoneError } = await supabase
      .from("curve_phone")
      .select("number")
      .eq("id", homePhoneId);

    if (!homePhoneError && homePhoneData) {
      phoneMap[homePhoneId] = homePhoneData[0]?.number;
    }
  }
  return phoneMap;
}

export async function fetchAddress(id) {
  function formatAddress(address) {
    if (!address) return "";
    const { Line1, Line2, city, country_sub_division, postal, country_code } =
      address;
    return `${Line1}${
      Line2 ? ", " + Line2 : ""
    }, ${city}, ${country_sub_division} ${postal}, ${country_code}`;
  }

  const addressMap = {};
  if (id) {
    const { data: address, error: addressError } = await supabase
      .from("curve_address")
      .select("*")
      .eq("id", id)
      .single();

    console.log("address: ", address);
    if (!addressError && address) {
      addressMap[id] = formatAddress(address);
    }
  }
  return addressMap;
}

export async function fetchPatients() {
  try {
    // Fetch data from patients table
    const { data: curvePatientsData, error: curvePatientsError } =
      await supabase.from("curve_patients").select("*");

    if (curvePatientsError) {
      console.error("Error fetching patients:", curvePatientsError);
      return []; // Return an empty array in case of an error
    }

    // Fetch data from new_patient_registrations table
    const { data: newPatientData, error: newPatientError } = await supabase
      .from("new_patient_registrations")
      .select("*");

    if (newPatientError) {
      console.error(
        "Error fetching new patient registrations:",
        newPatientError
      );
      return []; // Return an empty array in case of an error
    }

    // Merge patient data from both tables
    const mergedPatientsData = [
      ...newPatientData.map((patient) => ({
        ...patient,
        source: "local",
        readOnly: false,
      })),
      ...curvePatientsData.map((patient) => ({
        ...patient,
        source: "curveDental",
        readOnly: true,
      })),
    ];

    console.log("mergedPatientsData from fetchPatients: ", mergedPatientsData);
    return mergedPatientsData;
  } catch (error) {
    console.error("Error fetching patients:", error);
    return []; // Return an empty array in case of an error
  }
}

// fetches appointments patients from two different database and merges them
// RETURNS appointments with patient data as a field.
export async function fetchAppointments() {
  try {
    // Fetch data from Supabase appointments table
    const { data: supabaseData, error: supabaseError } = await supabase
      .from("appointments")
      .select("*");
    if (supabaseError)
      throw new Error(
        `Error fetching Supabase appointments: ${supabaseError.message}`
      );

    // Fetch data from Curve Dental appointments table
    const { data: curveData, error: curveError } = await supabase
      .from("curve_appointments")
      .select("*");
    if (curveError)
      throw new Error(
        `Error fetching Curve Dental appointments: ${curveError.message}`
      );

    // Fetch data from new_patient_registration table
    const { data: patientData, error: patientsError } = await supabase
      .from("new_patient_registrations")
      .select("*");
    if (patientsError)
      throw new Error(
        `Error fetching new patient registrations: ${patientsError.message}`
      );

    // Fetch data from Curve Dental patients table
    const { data: curvePatientsData, error: curvePatientsError } =
      await supabase.from("curve_patients").select();
    if (curvePatientsError)
      throw new Error(
        `Error fetching Curve Dental patients: ${curvePatientsError.message}`
      );

    // Map patient IDs to patient details for quick lookup
    const patientMap = new Map();

    patientData.forEach((patient) => {
      patientMap.set(patient.id, patient);
    });
    curvePatientsData.forEach((patient) => {
      // Assuming Curve patients have a unique identifier, adjust if needed
      patientMap.set(patient.id, patient);
    });
    console.log("patientMap: ", patientMap);
    // Merge Supabase and Curve Dental appointments with patient data
    const getPatientData = (patientId) => {
      const patient = patientMap.get(patientId);
      if (patient) {
        return patient;
      } else {
        console.warn(`Patient data missing for ID: ${patientId}`);
        return { id: patientId, isMissingData: true };
      }
    };

    // Merge Supabase and Curve Dental appointments with patient data
    const mergedData = [
      ...supabaseData.map((apt) => ({
        ...apt,
        source: "local",
        readOnly: false,
        patient: getPatientData(apt.patientId),
      })),
      ...curveData.map((apt) => ({
        ...apt,
        source: "curveDental",
        readOnly: true,
        patient: getPatientData(apt.patientId),
      })),
    ];

    console.log("mergedData: ", mergedData);
    return mergedData;
  } catch (error) {
    console.error("Error fetching appointments:", error);
    return [];
  }
}

// Modify functions to flag new appointments or patients as not synced.
export async function addAppointment(newAppointment) {
  const { error } = await supabase
    .from("appointments")
    .insert({ ...newAppointment, synced: false });
  if (error) {
    console.error("Error adding appointment to Supabase:", error);
  }
  return error;
}

// add sync field locally to indicate new appointments or patients as not synced.
export async function addPatient(newPatient) {
  const { error } = await supabase
    .from("curve_patients")
    .insert({ ...newPatient, synced: false });
  if (error) {
    console.error("Error adding patient to Supabase:", error);
  }
  return error;
}

export async function updateAppointment(id, updatedAppointment) {
  const { error } = await supabase
    .from("appointments")
    .update(updatedAppointment)
    .eq("id", id);
  if (error) {
    console.error(`Error updating appointment with id ${id}:`, error);
  }
  return error;
}

export async function deleteAppointment(id) {
  const { error } = await supabase.from("appointments").delete().eq("id", id);
  if (error) {
    console.error(`Error deleting appointment with id ${id}:`, error);
  }
  return error;
}

export async function fetchUniqueSessions() {
  try {
    const { data, error } = await supabase
      .from("conversationLog")
      .select("sessionId, created_at, is_read")
      .order("created_at", { ascending: false });

    if (error) throw error;

    return data; // Add this line to return the fetched data
  } catch (error) {
    console.error("Error fetching sessions:", error);
    return []; // Return an empty array in case of error
  }
}

// Fetch unique sessions from the conversation log
export const fetchUniqueSession = async () => {
  try {
    const { data, error } = await supabase
      .from("conversationLog")
      .select("sessionId, created_at, is_read")
      .order("created_at", { ascending: false });

    if (error) throw error;

    // Process the data to ensure each session is unique
    const sessionsMap = new Map();
    data.forEach((entry) => {
      if (!sessionsMap.has(entry.sessionId)) {
        sessionsMap.set(entry.sessionId, {
          created_at: entry.created_at,
          is_read: entry.is_read,
        });
      }
    });

    // Convert the map to an array and format dates
    return Array.from(sessionsMap, ([sessionId, { created_at, is_read }]) => ({
      sessionId,
      truncatedId: sessionId.substring(0, 8) + "...",
      date: new Date(created_at).toLocaleDateString(),
      time: new Date(created_at).toLocaleTimeString(),
      is_read,
    }));
  } catch (error) {
    console.error("Error fetching unique sessions:", error);
    return [];
  }
};

// Fetch summary for a specific session
export const fetchSessionSummary = async (sessionId) => {
  try {
    const { data, error } = await supabase
      .from("conversationLog")
      .select("summary")
      .eq("sessionId", sessionId)
      .limit(1)
      .single();

    if (error) throw error;
    return data?.summary || "";
  } catch (error) {
    console.error("Error fetching summary:", error);
    return "";
  }
};

// Mark a session as read
export const markSessionAsRead = async (sessionId) => {
  try {
    const { error } = await supabase
      .from("conversationLog")
      .update({ is_read: true })
      .eq("sessionId", sessionId);

    if (error) throw error;
  } catch (error) {
    console.error("Error marking session as read:", error);
  }
};

// Fetch messages for a specific session
export const fetchMessagesBySessionId = async (sessionId) => {
  try {
    const { data, error } = await supabase
      .from("conversationLog")
      .select("*")
      .eq("sessionId", sessionId)
      .order("created_at", { ascending: true });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching messages:", error);
    return [];
  }
};

export const liveDemoTry = async (phone, name, email) => {
  try {
    const { data, error } = await supabase
      .from("liveDemoTry")
      .insert({ phoneNumber: phone, name: name, email: email })
      .select(); // This will return the inserted row

    if (error) throw error;
    console.log("Inserted data:", data); // Check if data is successfully inserted
    return data;
  } catch (error) {
    console.error("Error inserting data:", error);
    return null;
  }
};
