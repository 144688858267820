import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
} from "recharts";
import PhoneIcon from "@mui/icons-material/Phone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import {
  getCallDurationForMonth,
  getCallVolumeForMonth,
  fetchDailyCallVolumeForWeek,
  fetchWeeklyCallVolumeForMonth,
  fetchMonthlyCallVolumeForYear,
  getCallDurationForLastMonth,
  getCallVolumeForLastMonth,
  getResolutionRateForWeek,
  getResolutionRateForMonth,
  getResolutionRateForYear,
} from "../backend/analytics";

function cn(...classes) {
  return classes.filter(Boolean).join(" ");
}

function calculatePercentageChange(current, previous) {
  if (previous === 0) return current > 0 ? 100 : 0;
  return ((current - previous) / Math.abs(previous)) * 100;
}

// Card component definitions
const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "rounded-lg border bg-white text-gray-800 shadow-md",
      className
    )}
    {...props}
  />
));
Card.displayName = "Card";

const Dashboard = () => {
  const [callVolume, setCallVolume] = useState(0);
  const [callDuration, setCallDuration] = useState(0);
  const [callVolumeData, setCallVolumeData] = useState([]);
  const [resolutionRate, setResolutionRate] = useState(0);
  const [resolutionRateData, setResolutionRateData] = useState([]);
  const [timeRange, setTimeRange] = useState("week");
  const [callVolumeChange, setCallVolumeChange] = useState(0);
  const [callDurationChange, setCallDurationChange] = useState(0);

  useEffect(() => {
    const fetchMetrics = async () => {
      // Fetch current month's metrics
      const volume = await getCallVolumeForMonth();
      setCallVolume(volume);

      const duration = await getCallDurationForMonth();
      setCallDuration(duration);

      const rate = await getResolutionRateForMonth();
      setResolutionRate(parseFloat(rate.toFixed(1)));

      // Fetch last month's metrics
      const lastMonthVolume = await getCallVolumeForLastMonth();
      const lastMonthDuration = await getCallDurationForLastMonth();

      // Calculate percentage changes
      const volumeChange = calculatePercentageChange(volume, lastMonthVolume);
      setCallVolumeChange(parseFloat(volumeChange.toFixed(1)));

      const durationChange = calculatePercentageChange(
        duration,
        lastMonthDuration
      );
      setCallDurationChange(parseFloat(durationChange.toFixed(1))); // Round to nearest tenth
    };

    fetchMetrics();
  }, []);

  useEffect(() => {
    const fetchCallVolumeData = async () => {
      let data = [];
      switch (timeRange) {
        case "week":
          data = await fetchDailyCallVolumeForWeek();
          break;
        case "month":
          data = await fetchWeeklyCallVolumeForMonth();

          // Calculate current and previous month call volumes
          const currentMonthVolume = data.reduce(
            (sum, week) => sum + week.calls,
            0
          );
          const previousMonthVolume = data.reduce((sum, week, index) => {
            return index === data.length - 1 ? sum : sum + week.calls; // Assuming last index represents previous month data
          }, 0);

          // Calculate the percentage change
          const volumeChange = calculatePercentageChange(
            currentMonthVolume,
            previousMonthVolume
          );
          setCallVolumeChange(parseFloat(volumeChange.toFixed(1)));
          break;
        case "year":
          data = await fetchMonthlyCallVolumeForYear();
          break;
        default:
          data = [];
      }
      setCallVolumeData(data);
    };

    const fetchResolutionRateData = async () => {
      let data = [];
      switch (timeRange) {
        case "week":
          data = await getResolutionRateForWeek();
          break;
        case "month":
          data = await getResolutionRateForMonth();
          break;
        case "year":
          data = await getResolutionRateForYear();
          break;
        default:
          data = [];
      }
      setResolutionRateData(data);
    };
    fetchCallVolumeData();

    fetchResolutionRateData();
  }, [timeRange]);

  useEffect(() => {
    // Fetch Call Volume
    const fetchCallVolume = async () => {
      const volume = await getCallVolumeForMonth();
      setCallVolume(volume);
    };

    // Fetch Call Duration
    const fetchCallDuration = async () => {
      const duration = await getCallDurationForMonth();
      setCallDuration(duration);
    };

    fetchCallVolume();
    fetchCallDuration();
  }, []);

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Metrics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        {/* Call Volume Card */}
        <Card className="p-4">
          <div className="flex items-center gap-4">
            <div className="p-2 bg-blue-50 rounded-lg">
              <PhoneIcon className="w-6 h-6 text-blue-600" />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <span className="text-gray-400 font-semibold text-xs uppercase tracking-wider">
                  Call Volume
                </span>
                <span
                  className={`text-sm ${
                    callVolumeChange >= 0 ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {Math.abs(callVolumeChange.toFixed(1))}%{" "}
                  {callVolumeChange >= 0 ? "↑" : "↓"}
                </span>
              </div>
              <h3 className="text-2xl font-bold text-gray-900">{callVolume}</h3>
              <p className="text-sm text-gray-600">
                Total calls handled this month.
              </p>
            </div>
          </div>
        </Card>

        {/* Call Resolution Rate Card */}
        <Card className="p-4">
          <div className="flex items-center gap-4">
            <div className="p-2 bg-green-50 rounded-lg">
              <CheckCircleIcon className="w-6 h-6 text-green-600" />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <span className="text-gray-400 font-semibold text-xs uppercase tracking-wider">
                  Resolution Rate
                </span>
                <span
                  className={`text-sm ${
                    resolutionRate >= 0 ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {Math.abs(resolutionRate.toFixed(1))}%{" "}
                  {resolutionRate >= 0 ? "↑" : "↓"}
                </span>
              </div>
              <h3 className="text-2xl font-bold text-gray-900">
                {resolutionRate}%
              </h3>
              <p className="text-sm text-gray-600 mt-2">
                Calls resolved without human intervention.
              </p>
            </div>
          </div>
        </Card>

        {/* Call Duration Card */}
        <Card className="p-4">
          <div className="flex items-center gap-4">
            <div className="p-2 bg-yellow-50 rounded-lg">
              <AccessTimeIcon className="w-6 h-6 text-yellow-600" />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <span className="text-gray-400 font-semibold text-xs uppercase tracking-wider">
                  Call Duration
                </span>
                <span
                  className={`text-sm ${
                    callDurationChange >= 0 ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {Math.abs(callDurationChange.toFixed(1))}%{" "}
                  {callDurationChange >= 0 ? "↑" : "↓"}
                </span>
              </div>
              <h3 className="text-2xl font-bold text-gray-900">
                {callDuration} min
              </h3>
              <p className="text-sm text-gray-600 mt-2">
                Total length of calls this Month.
              </p>
            </div>
          </div>
        </Card>

        {/* Call Transfer Rate Card */}
        <Card className="p-4">
          <div className="flex items-center gap-4">
            <div className="p-2 bg-red-50 rounded-lg">
              <TransferWithinAStationIcon className="w-6 h-6 text-red-600" />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <span className="text-gray-400 font-semibold text-xs uppercase tracking-wider">
                  Transfer Rate
                </span>
                <span className="text-red-500 text-sm">0.0% ↑</span>
              </div>
              <h3 className="text-2xl font-bold text-gray-900">--</h3>
              <p className="text-sm text-gray-600 mt-2">
                Calls transferred to human staff.
              </p>
            </div>
          </div>
        </Card>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {/* Line Chart for Call Volume */}
        <Card className="col-span-2 p-4">
          <div className="mb-4">
            <div className="flex items-center gap-4 mb-2">
              <h3 className="text-lg font-semibold">Call Volume</h3>
              <div className="flex gap-2">
                <button
                  onClick={() => setTimeRange("week")}
                  className={`px-3 py-1 ${
                    timeRange === "week"
                      ? "bg-blue-50 text-blue-600"
                      : "bg-white"
                  } rounded-full text-sm`}
                >
                  This Week
                </button>
                <button
                  onClick={() => setTimeRange("month")}
                  className={`px-3 py-1 ${
                    timeRange === "month"
                      ? "bg-blue-50 text-blue-600"
                      : "bg-white"
                  } rounded-full text-sm`}
                >
                  This Month
                </button>
                <button
                  onClick={() => setTimeRange("year")}
                  className={`px-3 py-1 ${
                    timeRange === "year"
                      ? "bg-blue-50 text-blue-600"
                      : "bg-white"
                  } rounded-full text-sm`}
                >
                  This Year
                </button>
              </div>
            </div>
          </div>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={callVolumeData}>
                <XAxis
                  dataKey={
                    timeRange === "year"
                      ? "month"
                      : timeRange === "month"
                      ? "week"
                      : "day"
                  }
                />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="calls"
                  stroke="#4F46E5"
                  strokeWidth={2}
                  name="Calls"
                />
                <Legend />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card>

        {/* Bar Chart for Call Resolution Rate */}
        <Card className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Call Resolution Rate</h3>
            <div className="flex gap-2">
              <button
                onClick={() => setTimeRange("week")}
                className={`px-3 py-1 ${
                  timeRange === "week" ? "bg-blue-50 text-blue-600" : "bg-white"
                } rounded-full text-sm`}
              >
                This Week
              </button>
              <button
                onClick={() => setTimeRange("month")}
                className={`px-3 py-1 ${
                  timeRange === "month"
                    ? "bg-blue-50 text-blue-600"
                    : "bg-white"
                } rounded-full text-sm`}
              >
                This Month
              </button>
              <button
                onClick={() => setTimeRange("year")}
                className={`px-3 py-1 ${
                  timeRange === "year" ? "bg-blue-50 text-blue-600" : "bg-white"
                } rounded-full text-sm`}
              >
                This Year
              </button>
            </div>
          </div>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={resolutionRateData}>
                <XAxis
                  dataKey={
                    timeRange === "year"
                      ? "month"
                      : timeRange === "month"
                      ? "week"
                      : "day"
                  }
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="rate" fill="#4F46E5" name="Resolution Rate (%)" />
                <Legend />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
