import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ExploreSolution = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);

  const features = [
    {
      title: "HIPAA-Compliant Security",
      description: `
        Our platform is designed to meet rigorous HIPAA standards, ensuring that every piece of patient information is safeguarded through advanced technology to ensure compliance with the highest standards of security and privacy.
      `,
    },
    {
      title: "24/7 Data Monitoring",
      description: `
        We conduct ongoing scanning and anonymization of data in real-time, ensuring all sensitive information remains secure and protected in structured and unstructured formats.
      `,
    },
    {
      title: "Easy Data Integration",
      description: `
       We believe accessing critical information should be as easy as it is secure. Our system is designed to integrate effortlessly with popular dental software, ensuring smooth access to patient records, scheduling, and billing information within your current workflows. Whether it’s accessing records or updating information, our solution supports fast, reliable access, giving you a seamless, intuitive experience without the need to overhaul existing systems.
      `,
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section ref={ref}>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 py-12 md:py-20">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold mb-4">
            Data Privacy and Security
          </h2>
          <p className="text-xl text-gray-600">
            Your data is our priority. Our solution is designed with the highest
            standards of security and privacy to protect your information at all
            times.
          </p>
        </div>

        <div className="space-y-4 flex flex-col items-center">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`transition duration-300 border rounded-lg p-4 w-full sm:w-2/3 lg:w-5/6 shadow-md ${
                openIndex === index
                  ? "bg-[#26add0] text-white shadow-lg"
                  : "bg-white text-gray-900"
              }`}
              onClick={() => handleToggle(index)}
            >
              <div className="flex justify-between items-center cursor-pointer">
                <h3 className="text-xl font-bold">{feature.title}</h3>
                <span className="text-2xl">
                  {openIndex === index ? "-" : "+"}
                </span>
              </div>
              {openIndex === index && (
                <p className="mt-4 text-lg">{feature.description}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});

export default ExploreSolution;
