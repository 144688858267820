import React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContactAgent from "./components/ContactAgent";

const HowitWorks = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const [tab, setTab] = useState(1);
  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current && tabs.current.parentElement)
      tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`;
  };

  useEffect(() => {
    heightFix();
  }, []);

  return (
    <div>
      <section className="relative" ref={ref}>
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-12 md:pt-20">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
              <h1 className="text-4xl font-extrabold mb-4">How it Works</h1>
            </div>

            {/* Flex container for the content and contact agent */}
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              {/* Content */}
              <div className="flex-1 mb-8 lg:mb-0" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                  <h3 className="text-3xl font-bold mb-3">
                    Operaitor Service Scenario
                  </h3>
                  <p className="text-xl text-gray-600">
                    Leave it to Operaitor to answer on your behalf and we will
                    handle the rest.
                  </p>
                </div>
                {/* Tabs buttons */}
                <div>
                  <a
                    className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                      tab !== 1
                        ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                        : "bg-gray-200 border-transparent"
                    }`}
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      setTab(1);
                    }}
                  >
                    <div>
                      <div className="font-bold leading-snug tracking-tight mb-1">
                        1. Customer Calls or Texts an Inquiry
                      </div>
                      <div className="text-gray-600">
                        Operator's AI sends tailored responses to patient using
                        pretrained medical knowledge as well as predefined rules
                        tailored to your specific practice's needs.
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                      tab !== 2
                        ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                        : "bg-gray-200 border-transparent"
                    }`}
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      setTab(2);
                    }}
                  >
                    <div>
                      <div className="font-bold leading-snug tracking-tight mb-1">
                        2. Execution
                      </div>
                      <div className="text-gray-600">
                        Operaitor handles appointment scheduling, check-in and
                        check-out. Automatically updating PMS.
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                      </svg>
                    </div>
                  </a>
                  <a
                    className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                      tab !== 3
                        ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                        : "bg-gray-200 border-transparent"
                    }`}
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      setTab(3);
                    }}
                  >
                    <div>
                      <div className="font-bold leading-snug tracking-tight mb-1">
                        3. Status Management
                      </div>
                      <div className="text-gray-600">
                        Doctors can track appointment status and patient
                        inquiries through the Operaitor App.
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z"
                          fill="#191919"
                          fillRule="nonzero"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>

              {/* Contact Agent - Wider Width */}
              <div className="flex-1 lg:max-w-2xl">
                <ContactAgent />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default HowitWorks;
