import React, { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import Avatar from "react-avatar";
import operaitorEmoji from "../assets/1024px.png";
import { fetchPatients } from "../backend/supabaseService";

// Create a Supabase client instance
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Utility function to format caller name
const formatCallerName = (caller) => {
  return caller.patientName === "Unknown Caller"
    ? `Unknown (${caller.phoneNumber})`
    : `${caller.patientName} (${caller.phoneNumber})`;
};

// Message component for individual messages
const Message = ({ message, callerName }) => {
  const isAssistant = message.role === "assistant";
  return (
    <div
      className={`flex items-start gap-2.5 mb-3 ${
        isAssistant ? "justify-end" : "justify-start"
      }`}
    >
      <Avatar
        name={isAssistant ? "AI Assistant" : callerName}
        src={isAssistant ? operaitorEmoji : null}
        round={true}
        size="40"
        className="w-8 h-8"
      />
      <div
        className={`flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 
        rounded-e-xl rounded-es-xl ${
          isAssistant
            ? "bg-blue-100 dark:bg-blue-700"
            : "bg-gray-100 dark:bg-gray-700"
        }`}
      >
        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          <span className="text-sm font-semibold text-gray-900 dark:text-white">
            {isAssistant ? "Assistant" : callerName}
          </span>
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {new Date(message.created_at).toLocaleTimeString()}
          </span>
        </div>
        <p className="text-sm font-normal py-1 text-gray-900 dark:text-white">
          {message.message}
        </p>
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {message.status}
        </span>
      </div>
    </div>
  );
};

function MessagePage() {
  const [messages, setMessages] = useState([]);
  const [uniqueCallers, setUniqueCallers] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

  useEffect(() => {
    const fetchUniqueCallersWithNames = async () => {
      try {
        // Fetch conversation data
        const { data: conversationData, error: conversationError } =
          await supabase
            .from("conversationLog")
            .select("phoneNumber")
            .order("created_at", { ascending: false });

        if (conversationError) throw conversationError;

        // Get unique phone numbers
        const uniqueNumbers = [
          ...new Set(conversationData.map((entry) => entry.phoneNumber)),
        ];

        // Fetch and process patient data
        const patientData = await fetchPatients();
        const phoneNumberToNameMap = {};

        patientData.forEach((patient) => {
          const fullName = `${patient.firstName || ""} ${
            patient.lastName || ""
          }`.trim();

          [
            patient.cellPhone_number,
            patient.homePhone_number,
            patient.workPhone_number,
          ]
            .filter(Boolean)
            .forEach((number) => {
              phoneNumberToNameMap[number] = fullName || "Unknown Caller";
            });
        });

        // Create unique callers array with names
        const callersWithNames = uniqueNumbers.map((number) => ({
          phoneNumber: number,
          patientName: phoneNumberToNameMap[number] || "Unknown Caller",
        }));

        setUniqueCallers(callersWithNames);
        if (callersWithNames.length > 0) {
          setSelectedPhoneNumber(callersWithNames[0].phoneNumber);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUniqueCallersWithNames();
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!selectedPhoneNumber) return;

      try {
        const { data, error } = await supabase
          .from("conversationLog")
          .select("*")
          .eq("phoneNumber", selectedPhoneNumber)
          .order("created_at", { ascending: true });

        if (error) throw error;
        setMessages(data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [selectedPhoneNumber]);

  const selectedCaller = uniqueCallers.find(
    (caller) => caller.phoneNumber === selectedPhoneNumber
  );
  const selectedCallerName = selectedCaller
    ? selectedCaller.patientName
    : "Unknown Caller";

  return (
    <div className="flex h-full">
      {/* Left Sidebar */}
      <div className="w-1/4 bg-gray-50 dark:bg-gray-800 p-4 overflow-auto">
        <h2 className="text-lg font-bold text-gray-900 dark:text-white mb-4">
          Call History
        </h2>
        {uniqueCallers.length > 0 ? (
          uniqueCallers.map((caller) => (
            <div
              key={caller.phoneNumber}
              onClick={() => setSelectedPhoneNumber(caller.phoneNumber)}
              className={`cursor-pointer p-2 rounded-lg mb-2 ${
                selectedPhoneNumber === caller.phoneNumber
                  ? "bg-blue-200 dark:bg-blue-700"
                  : "bg-white dark:bg-gray-700"
              }`}
            >
              <p className="text-sm font-medium text-gray-800 dark:text-white">
                {formatCallerName(caller)}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500 dark:text-gray-400">No callers found.</p>
        )}
      </div>

      {/* Right Panel */}
      <div className="flex-1 px-4 pt-3 overflow-auto">
        {selectedPhoneNumber ? (
          messages.length > 0 ? (
            messages.map((message) => (
              <Message
                key={message.id}
                message={message}
                callerName={selectedCallerName}
              />
            ))
          ) : (
            <p className="text-gray-500 dark:text-gray-400">
              No conversations found for this number.
            </p>
          )
        ) : (
          <p className="text-gray-500 dark:text-gray-400">
            Select a phone number to view conversations.
          </p>
        )}
      </div>
    </div>
  );
}

export default MessagePage;
