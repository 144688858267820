import React from "react";

const ScheduleGrid = ({ date, onPatientSelect, view, appointments }) => {
  const startHour = 6;
  const endHour = 20;
  const minuteHeight = 1.5; // 1px per minute
  const hourHeight = minuteHeight * 60; // 60px per hour
  const totalMinutes = (endHour - startHour) * 60;
  const totalHeight = totalMinutes * minuteHeight;

  const getStartDate = (appointment) => {
    let startDate;

    if (appointment.source === "curveDental") {
      startDate = new Date(appointment.startTime);
    } else if (appointment.source === "local") {
      const startDateStr = `${appointment.date}T${appointment.time}`;
      startDate = new Date(startDateStr);
    } else {
      const startDateStr =
        appointment.startTime || `${appointment.date}T${appointment.time}`;
      startDate = new Date(startDateStr);
    }

    return startDate;
  };

  const getDuration = (appointment) => {
    if (appointment.source === "curveDental") {
      return appointment.length || 30;
    } else if (appointment.source === "local") {
      return appointment.duration || 30;
    } else {
      return appointment.length || appointment.duration || 30;
    }
  };

  const getPatientName = (appointment) => {
    if (appointment.patient_name && appointment.patient_name !== "Unknown") {
      return appointment.patient_name;
    } else if (appointment.patient) {
      const firstName =
        appointment.patient.firstName || appointment.patient.first_name || "";
      const lastName =
        appointment.patient.lastName || appointment.patient.last_name || "";
      if (firstName || lastName) {
        return `${firstName} ${lastName}`.trim();
      } else if (appointment.patient.id) {
        return `Patient ID: ${appointment.patient.id}`;
      }
    } else if (appointment.phone_number) {
      return `Patient Phone: ${appointment.phone_number}`;
    }
    return "Unknown Patient";
  };

  const timeSlots = Array.from(
    { length: endHour - startHour + 1 },
    (_, i) => `${(i + startHour).toString().padStart(2, "0")}:00`
  );

  // Adjusted appointmentsByRoom to include all appointments
  const appointmentsByRoom = {};
  appointments.forEach((appointment) => {
    const roomKey = `Operatory ${appointment.operatoryId || "2"}`; // Default to "1" if operatoryId is missing
    if (!appointmentsByRoom[roomKey]) {
      appointmentsByRoom[roomKey] = [];
    }
    appointmentsByRoom[roomKey].push(appointment);
  });

  const rooms = Object.keys(appointmentsByRoom);

  if (rooms.length === 0) {
    return (
      <div className="flex items-center justify-center h-full text-gray-500">
        No appointments to display.
      </div>
    );
  }

  const renderAppointment = (
    appointment,
    isWeekView = false,
    dayIndex = 0,
    roomIndex = 0
  ) => {
    const startDate = getStartDate(appointment);

    if (isNaN(startDate)) {
      console.error("Invalid start date for appointment:", appointment);
      return null;
    }

    const startMinutes =
      (startDate.getHours() - startHour) * 60 + startDate.getMinutes();
    const duration = getDuration(appointment);

    // Validate if appointment is within schedule bounds
    if (startMinutes + duration <= 0 || startMinutes >= totalMinutes) {
      return null;
    }

    const adjustedStart = Math.max(0, startMinutes);
    const adjustedDuration = Math.min(duration, totalMinutes - adjustedStart);

    const topPosition = adjustedStart * minuteHeight;
    const heightPosition = adjustedDuration * minuteHeight;

    const positionStyle = {
      position: "absolute",
      top: `${topPosition}px`,
      height: `${heightPosition}px`,
      left: isWeekView
        ? `${(dayIndex / 7) * 100}%`
        : `${(roomIndex / rooms.length) * 100}%`,
      width: isWeekView ? `${100 / 7}%` : `${100 / rooms.length}%`,
      zIndex: 10,
    };

    return (
      <div
        key={`${appointment.id}-${startDate.toISOString()}`}
        className={`absolute p-2 rounded-lg shadow-md cursor-pointer text-sm transition-transform hover:scale-[1.02] ${
          appointment.source === "AI"
            ? "bg-blue-100 hover:bg-blue-200"
            : "bg-green-100 hover:bg-green-200"
        }`}
        style={positionStyle}
        onClick={() => onPatientSelect(appointment)}
      >
        <p className="font-bold truncate">{getPatientName(appointment)}</p>

        <p className="text-xs text-gray-600">
          {`${startDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })} - ${new Date(
            startDate.getTime() + duration * 60000
          ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`}
        </p>
        {appointment.source === "AI" && (
          <span className="text-blue-500 text-sm absolute top-1 right-1">
            🤖
          </span>
        )}
      </div>
    );
  };

  const getDaysInWeek = (date) => {
    const start = new Date(date);
    const dayOfWeek = start.getDay();
    start.setDate(start.getDate() - dayOfWeek);
    return Array.from({ length: 7 }, (_, i) => {
      const day = new Date(start);
      day.setDate(start.getDate() + i);
      return day;
    });
  };

  const renderDayView = () => (
    <div className="relative h-full w-full">
      {/* Room headers */}
      <div className="absolute top-0 left-0 w-full h-20 flex border-b border-gray-200 bg-white z-20">
        <div className="w-16" /> {/* Space for time labels */}
        {rooms.map((room) => (
          <div
            key={room}
            className="flex-1 p-2 text-center font-semibold text-gray-700 border-l border-gray-200"
          >
            {room}
          </div>
        ))}
      </div>

      {/* Time grid */}
      <div className="absolute top-20 left-0 right-0 bottom-0 overflow-auto">
        <div className="relative" style={{ height: `${totalHeight}px` }}>
          {/* Time labels */}
          <div className="absolute top-0 left-0 w-16 h-full">
            {timeSlots.map((time, index) => {
              const topPosition = index * hourHeight;
              return (
                <div
                  key={`time-${time}`}
                  className="absolute left-0 w-full flex items-center"
                  style={{
                    top: `${topPosition}px`,
                    height: `${hourHeight}px`,
                  }}
                >
                  <span className="text-xs text-gray-500 px-2">{time}</span>
                </div>
              );
            })}
          </div>

          {/* Grid lines */}
          <div className="absolute left-16 right-0 h-full">
            {timeSlots.map((_, index) => {
              const topPosition = index * hourHeight;
              return (
                <React.Fragment key={`grid-${index}`}>
                  {/* Main hour line */}
                  <div
                    className="absolute w-full border-t border-gray-200"
                    style={{
                      top: `${topPosition}px`,
                    }}
                  />
                  {/* 15-minute lines */}
                  {[1, 2, 3].map((quarter) => (
                    <div
                      key={`quarter-line-${index}-${quarter}`}
                      className="absolute w-full border-t border-gray-100"
                      style={{
                        top: `${topPosition + quarter * (hourHeight / 4)}px`,
                      }}
                    />
                  ))}
                </React.Fragment>
              );
            })}

            {/* Vertical grid lines */}
            {rooms.map((_, index) => (
              <div
                key={`vgrid-${index}`}
                className="absolute top-0 h-full border-l border-gray-200"
                style={{
                  left: `${(index / rooms.length) * 100}%`,
                  width: "1px",
                }}
              />
            ))}
          </div>

          {/* Appointments container */}
          <div className="absolute left-16 right-0 h-full">
            {rooms.map((room, roomIndex) =>
              appointmentsByRoom[room]
                ?.filter((apt) => {
                  const appointmentDate = getStartDate(apt);
                  return (
                    !isNaN(appointmentDate) &&
                    appointmentDate.toISOString().split("T")[0] ===
                      date.toISOString().split("T")[0]
                  );
                })
                .map((apt) => renderAppointment(apt, false, 0, roomIndex))
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const renderWeekView = () => {
    const days = getDaysInWeek(date);
    return (
      <div className="relative h-full w-full">
        {/* Day headers */}
        <div className="absolute top-0 left-0 w-full h-12 flex border-b border-gray-200 bg-white z-20">
          <div className="w-16" />
          {days.map((day) => (
            <div
              key={day.toISOString()}
              className="flex-1 p-2 text-center font-semibold text-gray-700 border-l border-gray-200 flex flex-col justify-center"
            >
              <div>{day.toLocaleDateString("en-US", { weekday: "short" })}</div>
              <div className="text-xs text-gray-500">
                {day.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })}
              </div>
            </div>
          ))}
        </div>

        {/* Time grid */}
        <div
          className="absolute top-12 left-0 right-0 bottom-0 overflow-auto"
          style={{ minHeight: `${totalHeight}px` }}
        >
          <div className="relative" style={{ height: `${totalHeight}px` }}>
            {/* Time labels */}
            <div className="absolute top-0 left-0 w-16 h-full">
              {timeSlots.map((time, index) => {
                const topPosition = index * hourHeight;
                return (
                  <div
                    key={`time-${time}`}
                    className="absolute left-0 w-full flex items-center"
                    style={{
                      top: `${topPosition}px`,
                      height: `${hourHeight}px`,
                    }}
                  >
                    <span className="text-xs text-gray-500 px-2">{time}</span>
                  </div>
                );
              })}
            </div>

            {/* Grid lines */}
            <div className="absolute left-16 right-0 h-full">
              {timeSlots.map((_, index) => {
                const topPosition = index * hourHeight;
                return (
                  <React.Fragment key={`grid-${index}`}>
                    {/* Main hour line */}
                    <div
                      className="absolute w-full border-t border-gray-200"
                      style={{
                        top: `${topPosition}px`,
                      }}
                    />
                    {/* 15-minute lines */}
                    {[1, 2, 3].map((quarter) => (
                      <div
                        key={`quarter-line-${index}-${quarter}`}
                        className="absolute w-full border-t border-gray-100"
                        style={{
                          top: `${topPosition + quarter * (hourHeight / 4)}px`,
                        }}
                      />
                    ))}
                  </React.Fragment>
                );
              })}

              {/* Vertical grid lines */}
              {days.map((_, index) => (
                <div
                  key={`vgrid-${index}`}
                  className="absolute top-0 h-full border-l border-gray-200"
                  style={{
                    left: `${(index / 7) * 100}%`,
                    width: "1px",
                  }}
                />
              ))}
            </div>

            {/* Appointments container */}
            <div className="absolute left-16 right-0 h-full">
              {days.map((day, dayIndex) =>
                rooms.map((room, roomIndex) =>
                  appointmentsByRoom[room]
                    ?.filter((apt) => {
                      const appointmentDate = getStartDate(apt);
                      return (
                        !isNaN(appointmentDate) &&
                        appointmentDate.toISOString().split("T")[0] ===
                          day.toISOString().split("T")[0]
                      );
                    })
                    .map((apt) =>
                      renderAppointment(apt, true, dayIndex, roomIndex)
                    )
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full bg-white rounded-lg shadow-lg overflow-auto">
      {view === "day" ? renderDayView() : renderWeekView()}
    </div>
  );
};

export default ScheduleGrid;
