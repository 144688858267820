import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

import thumb from "../assets/thumb.png";
import operaitorDemo1 from "../assets/Operaitordemo1.mp4";

const thumbWidth = 768;
const thumbHeight = 432;
const thumbAlt = "Modal Video Thumbnail";

const TitleSection = React.forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate(); // Initialize navigate function

  // Define the openModal and closeModal functions
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <section ref={ref} className="relative">
      {/* Background SVG */}
      {/* ... Your SVG code remains unchanged ... */}

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-12 md:pb-12">
          <div className="text-center pb-10 md:pb-2">
            {/* Heading */}
            <h1
              className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out"
            >
              Ai Front-Desk Tool
              <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                Operaitor
              </span>
            </h1>
            {/* Intro Text */}
            <div className="max-w-3xl mx-auto">
              <p
                className="text-xl text-gray-600 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Trusted Answering and Scheduling for Dental & Medical Practices.
              </p>
              {/* Buttons */}
              <div className="flex justify-center mb-8">
                <button
                  onClick={() =>
                    (window.location.href =
                      "https://calendly.com/johnlim0043/operaitor-demo")
                  }
                  className="bg-[#26add0] hover:bg-[#5bc1d5] text-white font-bold py-3 px-10 rounded-lg mr-4"
                >
                  Let's Talk!
                </button>
                <button
                  onClick={openModal}
                  className="border border-gray-400 text-gray-700 font-bold py-3 px-10 rounded-lg hover:bg-gray-100"
                >
                  See Demo
                </button>
              </div>
            </div>
            {/* Video Thumbnail */}
            <div>
              <div
                className="relative flex justify-center mb-16"
                data-aos="zoom-y-out"
                data-aos-delay="450"
              >
                <div className="flex flex-col justify-center">
                  <img
                    src={thumb}
                    width={thumbWidth}
                    height={thumbHeight}
                    alt={thumbAlt}
                  />
                </div>
                <button
                  className="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg"
                  onClick={openModal}
                >
                  {/* SVG icon */}
                  <svg
                    className="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 shrink-0"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                    <path d="M10 17l6-5-6-5z" />
                  </svg>
                  <span className="ml-3">Watch the full video (2 min)</span>
                </button>
              </div>
            </div>
            {/* Modal */}
            {modalOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                {/* Overlay */}
                <div
                  className="fixed inset-0 bg-black opacity-75"
                  onClick={closeModal}
                ></div>

                {/* Modal content */}
                <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-3xl w-full mx-auto z-50">
                  <div className="relative">
                    <button
                      className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                      onClick={closeModal}
                    >
                      &times;
                    </button>
                    <video
                      ref={videoRef}
                      className="w-full"
                      controls
                      src={operaitorDemo1}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* End of Modal */}
          </div>
        </div>
      </div>
    </section>
  );
});

export default TitleSection;
